<template>
  <div>
    <StadisticsInstallations
      v-if="showStadistics"
      :category-id="categoryStadisticas"
      :clientprop="clientStadisticas"
      :search-prop="searchStadisticas"
      :albaran-prop="numAlbaranStadisticas"
      :status-prop="statusStadisticas"
      :vida-util-prop="vida_utilStadisticas"
      :prox-revision-prop="prox_revisionStadisticas"
      :is-epi-prop="is_epiStadisticas"
      :user-prop="userStadisticas"
      :date-ini-prop="dateIniStadisticas"
      :date-fin-prop="dateFinStadisticas"
      :hidden="hidden"
    />
    <!-- :location-prop="locationStadisticas" -->
    <b-card no-body class="mb-1">
      <div class="d-flex justify-content-between align-items-center px-1 py-1">
        <h5 class="m-0 font-weight-bolder">
          {{ $t("installations") }}
        </h5>
        <div class="d-flex align-items-center">
          <div>
            <b-link v-if="subclient_id !== null && subclient_id !== undefined"
              :to="{ name: 'createInstallation', params: { subclient_id: subclient_id } }">
              <b-button v-if="currentRole == 'admin_cliente' || currentRole == 'super_admin'" variant="primary" class="mr-1 text-nowrap">
                {{ $t("AñadirInstallations") }}
              </b-button>
            </b-link>
            <b-link v-else :to="{ name: 'createInstallation' }">
              <b-button v-if="currentRole == 'admin_cliente'  ||currentRole == 'prl_cliente' || currentRole == 'super_admin'" variant="primary" class="mr-1 text-nowrap">
                {{ $t("AñadirInstallations") }}
              </b-button>
            </b-link>
          </div>
          <b-nav-item-dropdown
              right
              toggle-class="d-flex align-items-center dropdown-user-link"
              class="dropdown-user"
          >
            <template #button-content>
              <feather-icon
                  size="16"
                  icon="MoreHorizontalIcon"
              />
            </template>

            <b-dropdown-item
                :disabled="items.length === 0"
                @click="download()"
                link-class="d-flex align-items-center"
            >
              <feather-icon
                  size="16"
                  icon="DownloadIcon"
                  class="mr-50"
              />
              <span> {{ $t('Descargar lista Excel') }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <span class="ml-1 cursor-pointer" @click="visibleFilter = !visibleFilter">
            <feather-icon icon="FilterIcon" size="20" />
          </span>
        </div>
      </div>

      <div>
        <b-collapse v-model="visibleFilter" class="mt-2">
          <div class="px-2">
            <b-row>
              <b-col sm="4" style="padding-right: 0px">
                <b-form-group :label="$t('Busqueda')" label-for="filter-search">
                  <b-row>
                    <b-col sm="8">
                      <b-form-input v-model="searchTerm" :placeholder="$t('Busqueda')" type="text"
                        class="d-inline-block" />
                    </b-col>
                    <b-col sm="4" style="padding-left: 0px">
                      <b-button variant="primary" @click="handleSearch">
                        {{ $t("Buscar") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Estado')" label-for="filter-status">
                  <v-select v-model="status" label="name" :filterable="false" :searchable="false"
                    :options="selectStatusStore" multiple>
                    <template slot="option" slot-scope="option">
                      {{ $t("status.installations." + option.alias) }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ $t("status.installations." + option.alias) }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Categoría')" label-for="filter-category">
                  <SelectCategories
                    v-model="category"
                    type="installation"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('ProximaRevisionAntesDe')" label-for="filter-groups">
                  <v-select v-model="prox_revision" label="label" :filterable="false" :searchable="false"
                    :options="selectProxRevision" />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('FechaConstruccion')" label-for="filter-client">
                  <flat-pickr v-model="date" name="date" class="form-control" :config="dateConfig" />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Province')" label-for="filter-province">
                  <BaseSelect :resource="'/provinces'" @input="province = $event" :value="province" :label="'Provincia'"
                    :clearable="true" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>

      <b-table v-if="items" hover responsive :items="items" :fields="!currentClient ? fields : fieldsWithoutClient">
        <template #cell(image)="data">
          <b-link :to="{ name: 'viewInstallation', params: { id: data.item.id } }">
            <div class="d-flex align-items-center">
              <div :style="`background-image: url('${data.item.image ? data.item.image : defaultImage
      }');`" class="d-inline-block img-product-list mr-50" />
              <div class="d-inline-block m-0">
                <p class="mb-0">{{ data.item.name }}</p>
                <p class="ficha-info_loc" v-if="data.item.location">
                  {{ data.item.location.name }}
                </p>
              </div>
            </div>
          </b-link>
        </template>
        <template #cell(client)="data">
          <span v-if="data.item.client">
            {{ data.item.client.name }}
          </span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusInstallations[data.item.status.alias]">
            {{ $t(`status.installations.${data.item.status.alias}`) }}
          </b-badge>
        </template>
        <template #cell(last_review_date)="data">
          <span v-if="data.item.last_review_date">
            {{ dateColumn(data.item.last_review_date) }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(next_review_date)="data">
          <span v-if="data.item.next_review_date && data.item.next_review_date != 'null'
      ">
            <span v-if="data.item.next_review_date < nowTimestamp" class="text-red">
              {{ dateColumn(data.item.next_review_date) }}</span>
            <span v-else> {{ dateColumn(data.item.next_review_date) }}</span>
          </span>
          <span v-else>-</span>

          <b-badge v-if="!(data.item.next_review_date < nowTimestamp) &&
      data.item.useful_review
      " variant="light-info" class="ml-50">
            {{ data.item.useful_review }} {{ $t("meses") }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-link :to="{ name: 'viewInstallation', params: { id: data.item.id } }" class="mr-1">
            <feather-icon icon="EyeIcon" size="16" />
          </b-link>
          <template v-if="currentClient">
            <b-link :to="{ name: 'editInstallation', params: { id: data.item.id } }" class="mr-1">
              <feather-icon icon="Edit2Icon" size="16" />
            </b-link>
            <b-link class="mr-1">
              <feather-icon icon="CopyIcon" size="16" @click="cloneInstallation(data.item)" />
            </b-link>
          </template>
          <template v-else>
            <b-link v-if="data.item.archives && data.item.archives.review" :href="data.item.archives.review.url"
              target="_blank">
              <feather-icon icon="DownloadIcon" size="16" />
            </b-link>
          </template>
          <!--<span
            class="text-danger cursor-pointer"
            @click="deleteInstallation(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </span>-->
        </template>
      </b-table>
    </b-card>

    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
      </div>
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
          align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal id="modal-1" ref="modal-1" title="">
      <h3>{{ $t("InstalacionesTituloClonar") }}</h3>
      <p>
        {{ $t("InstalacionesTextoClonar") }}
      </p>
      <b-col md="12">
        <b-input-group>
          <template #prepend>
            <b-button variant="outline-secondary" @click="
                {
      numOfClones > 0
        ? (numOfClones = numOfClones - 1)
        : numOfClones;
    }
      ">
              -
            </b-button>
          </template>
          <b-form-input v-model="numOfClones" readonly type="number" :min="minClones"
            class="text-center font-weight-bolder" />
          <template #append>
            <b-button variant="outline-secondary" @click="
                {
      numOfClones < 20
        ? (numOfClones = numOfClones + 1)
        : numOfClones;
    }
      ">
              +
            </b-button>
          </template>
        </b-input-group>
        <!-- <h3 style="padding-top: 2rem; padding-bottom: 0.5rem">
          ¿En qué localización se ubican?
        </h3>
        <v-select
          v-model="localizacion"
          label="name"
          :filterable="false"
          :searchable="false"
          :clearable="false"
          :options="selectLocations"
          :placeholder="$t('Localizacion')"
        /> -->
      </b-col>

      <template #modal-footer="" style="justify-content: flex-start">
        <b-row style="width: 100%">
          <b-col md="7" />
          <b-col md="5" class="text-right">
            <b-button variant="primary" @click="clonar()">
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import {mapActions, mapGetters, mapMutations} from "vuex";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";
import StadisticsInstallations from "@/views/installations/Stadistics";
import flatPickr from "vue-flatpickr-component";
import SelectCategories from "@/components/selectCategories/SelectCategories.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import {BDropdownItem, BNavItemDropdown} from "bootstrap-vue";
import axios from "axios";
import DownloadService from "@/shared/services/download-service";

export default {
  components: {
    BNavItemDropdown, BDropdownItem,
    SelectCategories,
    vSelect,
    StadisticsInstallations,
    flatPickr,
    BaseSelect,
  },
  props: {
    clientprop: {
      type: String,
      default: "0",
    },
    userprop: {
      type: Number,
      default: 0,
    },
    subclient_id: {
      type: [Number, String],
      default: null,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    productprop: {
      type: String,
      default: '',
    },
    showStadistics: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultImage: require("@/assets/images/default/asset.png"),
      searchStadisticas: "",
      numAlbaranStadisticas: "",
      currentPage: 1,
      visibleFilter: false,
      pages: ["10", "15", "25"],
      pageLength: 10,
      statusInstallations: config.statusInstallationsVariants,
      searchTerm: "",
      numAlbaranTerm: "",
      search: "",
      status: [],
      category: "",
      province: "",
      prox_revision: "",
      is_epi: "",
      installationToClone: {},
      numOfClones: 1,
      minClones: 0,
      localizacion: {},
      nowTimestamp: Math.floor(Date.now() / 1000),
      date: "",
      date_ini: "",
      date_fin: "",
      dateConfig: {
        mode: "range",
      },
      selectProxRevision: [
        {
          label: this.$t("1mes"),
          value: 1,
        },
        {
          label: this.$t("3meses"),
          value: 3,
        },
        {
          label: this.$t("6meses"),
          value: 6,
        },
        {
          label: this.$t("1anyo"),
          value: 12,
        },
      ],
      vidaUtil: "",
      selectVidaUtil: [
        {
          label: this.$t("1mes"),
          value: 1,
        },
        {
          label: this.$t("3meses"),
          value: 3,
        },
        {
          label: this.$t("6meses"),
          value: 6,
        },
        {
          label: this.$t("1anyo"),
          value: 12,
        },
      ],
      fields: [
        {
          label: this.$t("NombreProducto"),
          key: "image",
        },
        {
          label: this.$t("Cliente"),
          key: "client",
        },
        {
          label: this.$t("Estado"),
          key: "status",
        },
        {
          label: this.$t("UltimaRevision"),
          key: "last_review_date",
        },
        {
          label: this.$t("ProximaRevision"),
          key: "next_review_date",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
        },
      ],
      fieldsWithoutClient: [
        {
          label: this.$t("NombreProducto"),
          key: "image",
        },
        {
          label: this.$t("Estado"),
          key: "status",
        },
        {
          label: this.$t("UltimaRevision"),
          key: "last_review_date",
        },
        {
          label: this.$t("ProximaRevision"),
          key: "next_review_date",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
          thStyle: { "min-width": "160px" },
        },
      ],
    };
  },
  watch: {
    is_epi() {
      this.currentPage = 1;
      this.chargeData();
    },
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
    province_id() {
      this.currentPage = 1;
      this.chargeData();
    },
    category() {
      this.currentPage = 1;
      this.chargeData();
    },
    prox_revision() {
      this.currentPage = 1;
      this.chargeData();
    },
    vidaUtil() {
      this.currentPage = 1;
      this.chargeData();
    },
    province() {
      this.currentPage = 1;
      this.chargeData();
    },
    date() {
      const range = this.date.split(" a ");

      this.date_ini = FlatPickrToTimestamp(range[0]);
      if (range.length > 1) {
        this.date_fin = FlatPickrToTimestamp(range[1]);
      } else {
        this.date_fin = "";
      }

      this.currentPage = 1;
      this.chargeData();
    },
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
      items: "installations/getItems",
      totalItems: "installations/getTotalItems",
      selectStatusStore: "installations/getSelectStatus",
      // selectProvinces: 'provinces/getItems',
      currentRole: "auth/getRole",
    }),
    categoryStadisticas() {
      return this.category ? this.category.id : "";
    },
    clientStadisticas() {
      let client = this.currentClient ? this.currentClient.id : "";
      if (this.clientprop) {
        client = this.clientprop;
      }
      return client;
    },
    statusStadisticas() {
      return this.status ? this.status.id : "";
    },
    vida_utilStadisticas() {
      return this.vidaUtil ? this.vidaUtil.value : "";
    },
    prox_revisionStadisticas() {
      return this.prox_revision ? this.prox_revision.value : "";
    },
    is_epiStadisticas() {
      return this.is_epi ? this.is_epi.value : "";
    },
    userStadisticas() {
      return this.userprop ? this.userprop : "";
    },
    dateIniStadisticas() {
      return this.date_ini ? this.date_ini : "";
    },
    dateFinStadisticas() {
      return this.date_fin ? this.date_fin : "";
    },
  },
  methods: {
    ...mapActions({
      clone: "installations/clone",
      delete: "installations/delete",
      list: "installations/getListInstallations",
      getSelectStatus: "installations/selectStatus",
    }),
    ...mapMutations({
      loading: 'app/loading',
    }),
    deleteInstallation(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t("EliminarConfirm", { nombre: item.name }), {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: this.$t("Eliminar"),
          cancelTitle: this.$t("Cancelar"),
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(item.id);
          }
        });
    },
    cloneInstallation(installation) {
      this.installationToClone = installation;
      this.$bvModal.show("modal-1");
    },
    clonar() {
      if (this.numOfClones > 0) {
        this.clone({
          idinstallation: this.installationToClone.id,
          // idlocation: this.localizacion.id,
          quantity: this.numOfClones,
        });
        this.$bvModal.hide("modal-1");
      }
    },
    chargeData() {
      let clienteCurrent = this.currentClient ? this.currentClient.id : "";
      const client =
        this.clientprop && this.clientprop !== "0"
          ? this.clientprop
          : clienteCurrent;
      const statusSelected = [];
      if (this.status.length > 0) {
        this.status.forEach((element) => {
          statusSelected.push(element.id);
        });
      }
      const category = this.category ? this.category.id : "";
      const province_id = this.province ? this.province.id : "";
      const vida_util = this.vidaUtil ? this.vidaUtil.value : "";
      const prox_revision = this.prox_revision ? this.prox_revision.value : "";
      this.searchStadisticas = this.searchTerm;
      const { productprop } = this

      this.list({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        status: statusSelected,
        category_id: category,
        province_id,
        prox_revision,
        date_ini: this.date_ini,
        date_fin: this.date_fin,
        product: productprop,
        subclient_id: this.subclient_id,
      });
    },
    dateColumn(date) {
      return TimestampToFlatPickr(date);
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    async download() {
      let clienteCurrent = this.currentClient ? this.currentClient.id : "";
      const client =
          this.clientprop && this.clientprop !== "0"
              ? this.clientprop
              : clienteCurrent;
      const user = this.userprop ? this.userprop : "";
      const statusSelected = [];
      if (this.status.length > 0) {
        this.status.forEach((element) => {
          statusSelected.push(element.id);
        });
      }
      const category = this.category ? this.category.id : "";
      const province_id = this.province ? this.province.id : "";
      const vida_util = this.vidaUtil ? this.vidaUtil.value : "";
      const prox_revision = this.prox_revision ? this.prox_revision.value : "";
      this.searchStadisticas = this.searchTerm;
      this.numAlbaranStadisticas = this.numAlbaranTerm;

      this.loading(true)
      try {
        const response = await axios({
          url: `${config.webApiBase}/installations/list/download`,
          data: {
            client,
            search: this.searchTerm,
            status: statusSelected,
            category_id: category,
            province_id,
            vida_util,
            prox_revision,
            user,
            date_ini: this.date_ini,
            date_fin: this.date_fin,
            num_albaran: this.numAlbaranTerm,
            subclient_id: this.subclient_id,
          },
          method: 'POST',
          responseType: 'blob',
        })
        DownloadService.resolveAndDownloadBlob(response.data, 'Instalaciones', 'xlsx')
      } catch (error) {
        this.$toast.error('Error en la descarga del listado. Por favor inténtelo de nuevo mas tarde.')
      }
      this.loading(false)
    },
  },
  async mounted() {
    await this.chargeData();
    await this.getSelectStatus();
    // await this.getSelectProvinces()
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.text-red {
  color: red;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";

.dropdown-user{
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after{
  display: none;
}
</style>
